<template>
  <div class="forecast-results">
    <div class="column" style="margin-bottom:0" v-loading="loading">
      <div class="flex-row align-items-center justify-between border-bottom padding-bottom-10">
        <div class="flex-row align-items-center">
          <div>我的预测结果</div>
          <div class="margin-left-20">
            <span class="color-sub">选择时间：</span>
            <el-date-picker
              style="width: 220px;"
              size="mini"
              :key="modelFreq"
              v-model="dateRangeVlaue"
              :type="modelFreq=='week'?'daterange':'monthrange'"
              :value-format="modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM'"
              range-separator="至"
              :start-placeholder="modelFreq=='week'?'开始日期':'开始日期'"
              :end-placeholder="modelFreq=='week'?'结束月份':'结束月份'"
              :clearable="false"
              :picker-options="setDateRange"
              @change="getPredictPlan">
            </el-date-picker>
          </div>
        </div>
        <div class="flex-row align-items-center">
         
          <el-button plain size="mini" @click="getComparison">对比查看</el-button>
        </div>
      </div>  

      <div class="flex-row border-bottom padding-top-10 text-nowrap">
        <div class="color-sub">选择产品：</div>
        <div class="flex-row flex-wrap">
          <div class="margin-bottom-10 margin-lr-10 hover-opacity" v-for="(item,index) in productList" :key="index" @click="selectProduct(item.productId)">
            <span :class="{'color-blue bold':productIds.indexOf(item.productId)>=0}">{{ item.productName }}</span>
          </div>
        </div>
      </div>

      <div class="flex-row border-bottom padding-top-10">
        <div class="color-sub">选择方案：</div>
        <div class="flex-row">
          <div class="margin-lr-10 hover-opacity" @click="selectexpertType()"><span :class="{'color-blue bold':!expertTypeIds.length}">全部</span></div>
          <div class="margin-bottom-10 margin-lr-10 hover-opacity" v-for="(item,index) in expertTypeList" :key="index" @click="selectexpertType(item.id)">
            <span :class="{'color-blue bold':expertTypeIds.indexOf(item.id)>=0}">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <el-table
        ref="datatable"
        :height="$root.docHeight-295"
        size="medium"
        :header-cell-class-name="row=>{return row.rowIndex==0&&row.columnIndex==0?'disable-all-selection':''}"
        :data="dataList"
        @selection-change="handleSelectionChange">
        <div slot="empty"><el-empty :image-size="150"></el-empty></div>
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column label="预测时间" prop="predictTime"></el-table-column>
        <el-table-column label="预测产品" prop="productName" width="150"></el-table-column>
        <el-table-column label="预测目标" prop="targetName"></el-table-column>
        <el-table-column label="预测方案" prop="planName"></el-table-column>
      </el-table>
    </div>

      <!-- 对比弹窗 -->
     <el-dialog custom-class="custom-dialog" :visible.sync="comparisonDetail.visible" class="forecast-results-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :append-to-body="true" top="50px" width="96%">
        <div slot="title" class="flex-row align-items-center justify-between border-bottom padding-bottom-10 padding-right-40">
          <div>模型构成对比（{{modelFreq=='week'?'周度预测':'月度预测'}}）</div>
          <div>
            <el-select v-model="comparisonDetail.modelCycle" size="mini" @change="getComparison" class="margin-right-20" v-if="modelFreq=='month'">
              <el-option :value="1" label="移动1期预测方案"></el-option>
              <el-option :value="3" label="移动3期预测方案"></el-option>
              <el-option :value="12" label="移动12期预测方案"></el-option>
            </el-select>
            <el-button  plain size="mini" @click="downLoadDataBySelect">导出模型构成对比</el-button>
          </div>
          
        </div>
        <div class="flex-column" :style="{'height': $root.docHeight-200+'px'}" v-loading="comparisonDetail.loading" v-if="comparisonDetail.visible">
          <div>
            <el-table
              size="small"
              :data="multipleSelection"
              >
              <div slot="empty"><el-empty :image-size="150"></el-empty></div>
              <el-table-column label="方案编号" show-overflow-tooltip width="80" align="center"><template slot-scope="props">{{ letters[props.$index]}}</template></el-table-column>
              <el-table-column label="预测时间" show-overflow-tooltip prop="predictTime"></el-table-column>
              <el-table-column label="预测期">
                  <template slot-scope="props">{{ props.row.predictCycle | dateFormat(modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM') }}</template>
                </el-table-column>
              <el-table-column label="预测产品" show-overflow-tooltip prop="productName" width="150"></el-table-column>
              <el-table-column label="预测目标" show-overflow-tooltip prop="targetName"></el-table-column>
              <el-table-column label="预测方案" show-overflow-tooltip prop="planName"></el-table-column>
            </el-table>
          </div>
          <div class="margin-top-10">
            <el-table
              :key="comparisonDetail.modelCycle"
              :max-height="$root.docHeight-330"
              :data="comparisonDetail.data.items"
              size="small"
              style="width: 100%">
              <div slot="empty"><el-empty :image-size="80"></el-empty></div>
              <el-table-column label="因子" show-overflow-tooltip min-width="150" fixed="left">
                <template slot-scope="props">{{ props.row.divisorName }}</template>
              </el-table-column>
              <el-table-column label="单位" align="center" show-overflow-tooltip min-width="50" fixed="left">
                <template slot-scope="props">{{ props.row.unit }}</template>
              </el-table-column>
              <el-table-column align="center" v-for="(item,index) in comparisonDetail.data.dataList" :key="index" :label="$dateFormat(item.dataCycle,modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')">
                <el-table-column align="center" label="真实值">
                  <template slot-scope="props">{{ item[props.row.id]['hisValue'] | numFormat(2,'-') }}</template>
                </el-table-column>
                <el-table-column align="center" v-for="(item2,index2) in multipleSelection" :key="index2" :label="`方案${letters[index2]}预测`">
                  <template slot-scope="props">{{ item[props.row.id][item2.publishId] | numFormat(2,'-') }}</template>
                </el-table-column>
                
              </el-table-column>
              
            </el-table>
          </div>
        </div>
      </el-dialog>
  </div>
</template>

<script>

import exportExcel  from "@/common/exportExcel";
export default {
  name: 'ModelComposition',
  components:{

  },
  props:{
    modelFreq:{
      type:String,
      default:'week'
    },
    productList:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data (){
    return{
      setDateRange: {
        disabledDate: time => {
          // 禁用今天之后的日期【当前天可选】
          return time > new Date();
        },
        // firstDayOfWeek:1
      },
      dateRangeVlaue:'',//选择的时间范围
      productIds:[],//选择的产品
      expertTypeList:[{name:'模型自估预测',id:'ai'},{name:'卓创赋值预测',id:'sci'},{name:'滨化赋值预测',id:'befar'}],
      expertTypeIds:[],//选择的产品
      multipleSelection: [],//勾选的列表
      loading:false,
      dataList: [],
      comparisonDetail:{//对比弹窗
        visible:false,
        loading:false,
        modelCycle:1,
        data:{

        }
      },
      letters:['A','B','C','D','E','F','G','H','I','J','K']
    }
  },
  computed:{

  },
  created(){
    this.initDate()
    this.getData()
  },
  mounted(){

  },
  methods:{
    getData(){
      if(this.productList.length){
        !this.productIds.length&&(this.productIds=[this.productList[0].productId])
        this.getPredictPlan()
      }
    },
    //初始化日期
    initDate(){
      let currentDate = new Date(); // 获取当前日期
      currentDate.setMonth(currentDate.getMonth() - (this.modelFreq=='week'?1:2)); // 将当前日期减去N天
      this.dateRangeVlaue = this.modelFreq=='week'?[currentDate.format('yyyy-MM-dd'),new Date().format('yyyy-MM-dd')]:[currentDate.format('yyyy-MM'),new Date().format('yyyy-MM')]
    },
    
    //选择产品
    selectProduct(id){
      this.productIds=[id]

      this.getPredictPlan()
    },
    //选择方案类型
    selectexpertType(id){
      if(id){
        let index=this.expertTypeIds.indexOf(id)
        if(index>=0){
          this.expertTypeIds.splice(index,1)
        }else{
          this.expertTypeIds.push(id)
        }
      }else{
        this.expertTypeIds=[]
      }
      this.getPredictPlan()
    },
    //勾选列表
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if(val.length>2){
        this.$refs.datatable.toggleRowSelection(val[val.length-1],false);
        this.$message({message: '最多选择2个预测方案',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
      }
    },
    //获取列表信息
    getPredictPlan(){
      this.loading=true
      let request=this.$instance.get('/resultView/getPredictPlan',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           startDate:this.dateRangeVlaue[0],
           endDate:this.dateRangeVlaue[1],
           productId:this.productIds.length?this.productIds.join(','):this.productList[0].productId,
           expertType:this.expertTypeIds.join(',')
          },
      })
      request.then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.dataList=res.data.info
        }else{
          this.dataList=[]
        }
      })
      request.catch(()=>{
        this.loading=false
        this.targetInfo=[]
      })
      return request
    },


    //获取对比结果
    getComparison(){
      if(!this.multipleSelection.length){
        this.$message({message: '请选择要对比的预测方案',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      this.comparisonDetail.visible=true
     
      this.comparisonDetail.loading=true
      let request=this.$instance.get('/resultView/getDivisorsOfModelByModelCycle',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           modelCycle:this.modelFreq=='week'?1:this.comparisonDetail.modelCycle,
           publishIds:this.multipleSelection.map(v=>{return v.publishId}).join(',')
          },
      })
      request.then(res=>{
        this.comparisonDetail.loading=false
        if(res.data.code==0){
          this.comparisonDetail.data=res.data.info
        }else{
          this.comparisonDetail.data={}
        }
      })
      request.catch(()=>{
        this.comparisonDetail.loading=false
        this.comparisonDetail.data={}
      })
      return request
    },
    //导出所选
    downLoadDataBySelect(){
      
      let query={
        modelFreq:this.modelFreq,
        modelCycle:this.modelFreq=='week'?1:this.comparisonDetail.modelCycle,
        publishIds:this.multipleSelection.map(v=>{return v.publishId}).join(',')
      }
      exportExcel('/resultView/exportModelOfDivisorResultByModelCycle',query,'get');
    },
   
  },
  
  watch: {
    'productList':{
      handler:function(){
        this.getData()
      },
      deep:true,
    },
    "modelFreq" : {
     handler:function(){
      this.initDate()
      this.getData()
     },
     deep:true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>

 
</style>
<style lang="scss">

  .forecast-results-dialog{
    .el-tabs__header{margin: 0;}
    .el-dialog__body{
      padding-top:0px
    }
  }

</style>